import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
  <Layout>
    <SEO title="Terms Of Use" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Terms Of Use</h1>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsPage
